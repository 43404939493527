import React, { Component } from "react";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import "../styles/Navbar.css";

export class Navbar extends Component {
    render() {
        return (
            <>
                <Container>
                    <Row className="justify-content-md-center">
                        <Col xs lg="3"></Col>
                        <Col lg="3"></Col>
                        <Col md="auto" lg="auto">
                            <Nav
                                className="justify-content-center"
                                activeKey=""
                            >
                                <Nav.Item>
                                    <Link to=''>home</Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Link to='/projects'>projects</Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Link to='/contact'>contact</Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default Navbar;
