import React, { Component } from "react";
import "../styles/footer.css";
import { BsEnvelope, BsLinkedin, BsGithub } from "react-icons/bs";

export class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <p className="icons">
                    <a target="_blank" href="mailto:contact@aldendemello.com">
                        <BsEnvelope size={21} />
                    </a>
                    <a
                        target="_blank"
                        href="https://linkedin.com/in/aldendemello"
                    >
                        <BsLinkedin size={21} />
                    </a>
                    <a target="_blank" href="https://github.com/aldendemello">
                        <BsGithub size={21} />
                    </a>
                </p>
            </div>
            //comment
        );
    }
}

export default Footer;
