import React, { Component } from "react";
import "../styles/HomeIntro.css";
import Container from "react-bootstrap/Container";
import face from "../assets/img/face.jpeg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BsEnvelope, BsLinkedin, BsGithub } from "react-icons/bs";
import Footer from "../components/Footer";
import MediaQuery from "react-responsive";
export class HomeIntro extends Component {
    render() {
        return (
            <>
                <Row className="align-items-center viewport-height">
                    <Col>
                        <img className="profile" src={face} alt="face" />
                        <h1>Hi, I'm Alden DeMello!</h1>
                        <p>
                            I am currently a computer science student at Queen's
                            University.
                        </p>
                        <p>
                            I have acquired 4+ years of knowledge building small
                            to medium sized applications. I hope to better
                            myself and work in a environment where I am
                            constantly being challenged.
                        </p>

                        <p>
                            Feel free to check out my past projects or get in
                            touch with me!
                        </p>
                    </Col>
                </Row>

                <MediaQuery maxWidth={1224}>
                    <Footer></Footer>
                </MediaQuery>
            </>
        );
    }
}

export default HomeIntro;
