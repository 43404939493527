import React, { Component } from "react";
import { Card } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CardGroup from "react-bootstrap/CardGroup";
import "../styles/projects.css";
import game1 from "../assets/img/g2.png";
import game3 from "../assets/img/g1.png";

export class Projects extends Component {
    render() {
        return (
            <>
                <Row className="align-items-center pj-viewport-height">
                    <Col>
                        <h3>Featured</h3>
                        <CardGroup>
                            <Card>
                                <a
                                    target="_blank"
                                    href="https://github.com/aldendemello/KingdomTD"
                                >
                                    <Card.Img variant="top" src={game1} />
                                </a>

                                <Card.Body>
                                    <Card.Title>Kingdom TD</Card.Title>
                                    <Card.Text>
                                        A completely unique tower defence game
                                        with a twist! Dylan - a playable
                                        character who can be upgraded and used
                                        in battle.
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer>
                                    <small className="text-muted">
                                        <p>Unity</p>
                                        <p>C#</p>
                                    </small>
                                </Card.Footer>
                            </Card>
                            <Card>
                                <Card.Img
                                    variant="top"
                                    src="https://images.unsplash.com/photo-1540221652346-e5dd6b50f3e7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1738&q=80"
                                />
                                <Card.Body>
                                    <Card.Title>Pouxa</Card.Title>
                                    <Card.Text>
                                        A platform to help people share
                                        different outfits and find new
                                        inspiration for otherwise old clothes.
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer>
                                    <small className="text-muted">
                                        <p>MongoDB</p>
                                        <p>Express</p>
                                        <p>React.js</p>
                                        <p>Node</p>
                                        <p>Bootstrap</p>
                                    </small>
                                </Card.Footer>
                            </Card>
                            <Card>
                                <a
                                    target="_blank"
                                    href="https://github.com/aldendemello/Prism"
                                >
                                    <Card.Img variant="top" src={game3} />
                                </a>
                                <Card.Body>
                                    <Card.Title>Prism</Card.Title>
                                    <Card.Text>
                                        "Lost in an arcade, young Dylan has to
                                        try and escape by playing custom arcade
                                        games". A game created for a grade 12
                                        school project.
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer>
                                    <small className="text-muted">
                                        <p>Python</p>
                                        <p>PyGame</p>
                                        <p>SQL/SQLite3</p>
                                    </small>
                                </Card.Footer>
                            </Card>
                        </CardGroup>
                    </Col>
                </Row>
                
            </>
        );
    }
}

export default Projects;
