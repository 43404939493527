import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import face from "../assets/img/face.jpeg";
import "../styles/Contact.css";

export class Contact extends Component {
    render() {
        return (
            <>
                <Row className="align-items-center viewport-height">
                    <Col>
                        <img className="profile" src={face} alt="face" />
                        <h1>Connect with me</h1>
                        <p className="buttons">
                            <a
                                target="_blank"
                                href="mailto:contact@aldendemello.com"
                            >
                                Email
                            </a>
                            <a
                                target="_blank"
                                href="https://www.linkedin.com/in/aldendemello/"
                            >
                                LinkedIn
                            </a>
                            <a
                                target="_blank"
                                href="https://github.com/aldendemello/"
                            >
                                GitHub
                            </a>
                        </p>
                    </Col>
                </Row>
            </>
        );
    }
}

export default Contact;
