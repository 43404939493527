import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
export class NotFound extends Component {
    render() {
        return (
            <>
                <Row className="align-items-center viewport-height">
                    <Col>
                        <h1>Page Not Found</h1>
                        <p>Error 404</p>
                    </Col>
                </Row>
            </>
        );
    }
}

export default NotFound;
