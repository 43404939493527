import Navbar from "./components/Navbar";
import { Stack } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import HomeIntro from "./components/HomeIntro";
import Projects from "./components/Projects";
import Footer from "./components/Footer";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import NotFound from "./components/NotFound";
import Contact from "./components/Contact";
import MediaQuery from "react-responsive";
function App() {
    return (
        <div className="App">
            <Navbar></Navbar>
            <Routes>
                <Route path="/" element={<HomeIntro />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
            <MediaQuery minWidth={768}>
                <Footer></Footer>
            </MediaQuery>
        </div>
    );
}

export default App;
